import * as React from "react"
import { graphql } from "gatsby"

import { Layout } from "../../components/layout"
import SEO from "../../components/seo"
import { PaddleBrowser } from "../../components/PaddleBrowser"
import { Hero } from "../../components/Hero"
import { Paddle } from "../../models/Paddle"
import { createFilters } from "../../helpers/filters"

const IndexPage = ({ data }) => {
  const paddles: Paddle[] = data.allPaddlesCsv.edges.map(paddle => {
    const paddleInfo = paddle.node
    const msrp = paddleInfo.msrp.replace(/\$/g, "").trim()
    let transformedPaddle: Paddle = {
      brand: paddleInfo.brand,
      handle: paddleInfo.handle,
      core_material: paddleInfo.core_material,
      description: paddleInfo.description,
      discontinued: paddleInfo.discontinued,
      grip_circumference: Number(paddleInfo.grip_circumference),
      grip_length: Number(paddleInfo.grip_length),
      made_in_usa: (paddleInfo.made_in_usa as string).toLowerCase() === "y",
      msrp: Number(msrp),
      name: paddleInfo.name,
      notes: paddleInfo.notes,
      oversized: (paddleInfo.oversized as string).toLowerCase() === "y",
      paddle_length: Number(paddleInfo.paddle_length),
      paddle_width: Number(paddleInfo.paddle_width),
      surface_material: paddleInfo.surface_material,
      weight_max: Number(paddleInfo.weight_max),
      weight_min: Number(paddleInfo.weight_min),
      image_urls: paddleInfo.image_urls ? paddleInfo.image_urls.split(";") : [],
    }
    return transformedPaddle
  }).filter(paddle => paddle.image_urls && paddle.image_urls.length > 0) // todo remove

  const filters = createFilters(paddles)
  return (
    <Layout>
      <SEO title="Pickleball Paddle Finder" />
      <Hero title="Find your next paddle" subtitle="" />
      <PaddleBrowser paddles={ paddles } filters={ filters } />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
    query PaddleQuery {
        allPaddlesCsv {
            edges {
                node {
                    brand
                    handle
                    core_material
                    description
                    discontinued
                    grip_circumference
                    grip_length
                    made_in_usa
                    msrp
                    name
                    oversized
                    notes
                    paddle_length
                    paddle_width
                    surface_material
                    weight_max
                    weight_min
                    image_urls
                }
            }
        }
    }
`
