import * as React from "react"
import { Paddle } from "../../models/Paddle"
import { PaddleSpecifications } from "../PaddleSpecifications"
import { Image } from "../Img"
import "./styles.scss"
import { FaTimesCircle } from "react-icons/fa"

interface Props {
  closeFn: () => void;
  paddles: Paddle[];
}

export const PaddleComparator = ({ closeFn, paddles }: Props) => (
  <div id="PaddleComparatorModal" className="modal is-active">
    <div className="modal-background" onClick={ closeFn } />
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">Compare Paddles</p>
        <i className="icon is-medium is-white close-modal" onClick={ closeFn }>
          <FaTimesCircle size="2rem" color="#000" />
        </i>
      </header>
      <section className="modal-card-body">
        <div className="columns is-multiline">
          { paddles.map(paddle => (
            <div key={ paddle.handle } className="column">
              <h4 className="is-size-4 has-text-centered">{ paddle.name }</h4>
              <Image filePath={ paddle.image_urls[0] } />
              <PaddleSpecifications paddle={ paddle } />
            </div>
          )) }
        </div>
      </section>
    </div>
  </div>
)
