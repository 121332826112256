import { Paddle } from "../../models/Paddle"
import * as React from "react"
import "./styles.scss"
import { PaddleComparator } from "../PaddleComparison"

interface Props {
  selectedPaddlesToCompare: { [x: string]: Paddle };
  removePaddle: (p: Paddle) => void;
}

export const PaddleComparisonSidePanel = ({ selectedPaddlesToCompare, removePaddle }: Props) => {
  const handles = Object.keys(selectedPaddlesToCompare)
  if (!handles.length) {
    return null
  }

  const [showComparison, setShowComparison] = React.useState(false)

  const toggleCompareModal = () => setShowComparison(!showComparison)

  return (
    <div className="box" id="PaddleComparisonSidePanel">
      <div className="field is-grouped is-grouped-multiline">
        { handles.map((handle) => (
          <div key={ handle } className="control">
            <div className="tags has-addons">
              <span className="tag is-dark">{ selectedPaddlesToCompare[handle].name }</span>
              <a className="tag is-delete is-dark" onClick={ () => removePaddle(selectedPaddlesToCompare[handle]) } />
            </div>
          </div>
        )) }
      </div>
      <button className="button is-link is-fullwidth"
              onClick={ toggleCompareModal }>Compare
      </button>
      { showComparison
        ? <PaddleComparator closeFn={ toggleCompareModal }
                            paddles={ Object.values(selectedPaddlesToCompare) } />
        : null }
    </div>
  )
}

