import * as React from "react"

interface Props {
  min: string | number;
  max: string | number;
  label: string;
  value: string;
  placeholder: string;
  setValue: (x: string) => void;
}

// todo debounce method for onChange call in input. not sure if should live here or in the hook (custom hook?)
export const NumberInputField = ({ min, max, label, value, placeholder, setValue }: Props) => (
  <>
    <p className="menu-label">{ label }</p>
    <input className="input"
           type="number"
           min={ min }
           max={ max }
           value={ value }
           onChange={ (e) => setValue(e.target.value) }
           placeholder={ placeholder } />
  </>
)
