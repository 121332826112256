import * as React from "react"
import { Paddle } from "../../models/Paddle"
import { PaddleCard } from "../PaddleCard"
import { filterPaddlesByFilterCriteria, Filters } from "../../helpers/filters"
import { Dropdown } from "../Dropdown"
import { NumberInputField } from "../NumberInputField"
import "./styles.scss"
import { FaFilter } from "react-icons/fa"
import { PaddleComparisonSidePanel } from "../PaddleComparisonSidePanel"

interface Props {
  paddles: Paddle[]
  filters: Filters
}

const hideFilterClass = "is-hidden-touch"
const toggleFiltersDisplay = () => {
  const filterDiv = document.getElementById("PaddleFilters")
  const currentClassName = filterDiv.className
  if (currentClassName.includes(hideFilterClass)) {
    filterDiv.className = currentClassName.replace(hideFilterClass, "")
    return
  }
  filterDiv.className = currentClassName + ` ${ hideFilterClass }`
}

export const PaddleBrowser = ({ paddles, filters }: Props) => {
  // console.log("paddle data: ,", paddles)
  const absoluteMinPrice = filters.priceRange[0].toString()
  const absoluteMaxPrice = filters.priceRange[1].toString()
  const absoluteMinWeight = filters.weightRange[0].toString()
  const absoluteMaxWeight = filters.weightRange[1].toString()
  const absoluteMinLength = filters.paddleLengthRange[0]
  const absoluteMaxLength = filters.paddleLengthRange[1]
  const absoluteMinWidth = filters.paddleWidthRange[0]
  const absoluteMaxWidth = filters.paddleWidthRange[1]

  const [searchName, setSearchName] = React.useState("")
  const [selectedBrand, setSelectedBrand] = React.useState("")
  const [selectedCoreMaterial, setSelectedCoreMaterial] = React.useState("")
  const [selectedSurfaceMaterial, setSelectedSurfaceMaterial] = React.useState("")
  const [selectedGripLength, setSelectedGripLength] = React.useState("")
  const [selectedGripCircumference, setSelectedGripCircumference] = React.useState("")
  const [selectedMinFaceWidth, setSelectedMinFaceWidth] = React.useState("")
  const [selectedMaxFaceWidth, setSelectedMaxFaceWidth] = React.useState("")
  const [selectedMinFaceLength, setSelectedMinFaceLength] = React.useState("")
  const [selectedMaxFaceLength, setSelectedMaxFaceLength] = React.useState("")
  const [selectedMinPrice, setSelectedMinPrice] = React.useState("")
  const [selectedMaxPrice, setSelectedMaxPrice] = React.useState("")
  const [selectedMinWeight, setSelectedMinWeight] = React.useState("")
  const [selectedMaxWeight, setSelectedMaxWeight] = React.useState("")
  const [selectedPaddlesToCompare, setSelectedPaddlesToCompare] = React.useState({})

  const filteredPaddles = filterPaddlesByFilterCriteria(paddles,
    searchName,
    selectedBrand,
    Number(selectedMinPrice || absoluteMinPrice),
    Number(selectedMaxPrice || absoluteMaxPrice),
    Number(selectedMinWeight || absoluteMinWeight),
    Number(selectedMaxWeight || absoluteMaxWeight),
    selectedCoreMaterial,
    selectedSurfaceMaterial,
    Number(selectedGripLength),
    Number(selectedGripCircumference),
    Number(selectedMinFaceLength || absoluteMinLength),
    Number(selectedMaxFaceLength || absoluteMaxLength),
    Number(selectedMinFaceWidth || absoluteMinWidth),
    Number(selectedMaxFaceWidth || absoluteMaxWidth),
  )

  const resetFilters = () => {
    setSelectedBrand("")
    setSearchName("")
    setSelectedCoreMaterial("")
    setSelectedSurfaceMaterial("")
    setSelectedGripLength("")
    setSelectedGripCircumference("")
    setSelectedMinPrice("")
    setSelectedMaxPrice("")
    setSelectedMinWeight("")
    setSelectedMaxWeight("")
    setSelectedMinFaceLength("")
    setSelectedMaxFaceLength("")
    setSelectedMinFaceWidth("")
    setSelectedMaxFaceWidth("")
  }

  const togglePaddleToCompare = (paddle: Paddle) => {
    const comparePaddles = Object.assign({}, selectedPaddlesToCompare)
    const handle = paddle.handle

    if (comparePaddles[handle]) {
      delete comparePaddles[handle]
    } else {
      if (Object.keys(selectedPaddlesToCompare).length > 4) {
        // TODO show warning modal 5 max paddles
        return
      }
      comparePaddles[handle] = paddle
    }

    setSelectedPaddlesToCompare(comparePaddles)
  }

  // console.log("filtered paddles", filteredPaddles)

  return (
    <>
      <div className="section">
        <div className="container">
          <div className="columns is-mobile is-multiline">
            <div className="column is-12-desktop is-10-touch">
              <input onChange={ (e) => setSearchName(e.target.value) }
                     type="text"
                     placeholder="Search Paddles by Name..."
                     className="input is-medium is-12-desktop" />
            </div>
            <div className="column is-2-touch is-hidden-desktop filter-icon">
              <i className="icon is-medium is-white" onClick={ toggleFiltersDisplay }>
                <FaFilter size="2rem" color="#FA4616" />
              </i>
            </div>
            <div className="column is-3-desktop is-12-touch">
              <PaddleComparisonSidePanel selectedPaddlesToCompare={ selectedPaddlesToCompare }
                                         removePaddle={ togglePaddleToCompare } />
              <aside id="PaddleFilters" className="menu box is-hidden-touch">
                <Dropdown label="Brand"
                          value={ selectedBrand }
                          options={ filters.brand }
                          selectClass="capitalize"
                          setSelected={ setSelectedBrand } />
                <Dropdown label="Core Material"
                          value={ selectedCoreMaterial }
                          options={ filters.coreMaterial }
                          setSelected={ setSelectedCoreMaterial } />
                <Dropdown label="Surface Material"
                          value={ selectedSurfaceMaterial }
                          options={ filters.surfaceMaterial }
                          setSelected={ setSelectedSurfaceMaterial } />
                <Dropdown label="Grip Circumference"
                          value={ selectedGripCircumference }
                          options={ filters.gripCircumference }
                          setSelected={ setSelectedGripCircumference } />
                <Dropdown label="Grip Length"
                          value={ selectedGripLength }
                          options={ filters.gripLength }
                          setSelected={ setSelectedGripLength } />
                <NumberInputField placeholder={ `min paddle width: ${ absoluteMinWidth }in` }
                                  label="Min paddle width"
                                  min={ absoluteMinWidth }
                                  max={ selectedMaxFaceWidth }
                                  value={ selectedMinFaceWidth }
                                  setValue={ setSelectedMinFaceWidth } />
                <NumberInputField placeholder={ `max paddle width: ${ absoluteMaxWidth }in` }
                                  label="Max paddle width"
                                  min={ selectedMinFaceWidth }
                                  max={ absoluteMaxWidth }
                                  value={ selectedMaxFaceWidth }
                                  setValue={ setSelectedMaxFaceWidth } />
                <NumberInputField placeholder={ `min paddle length: ${ absoluteMinLength }in` }
                                  label="Min paddle length"
                                  min={ absoluteMinLength }
                                  max={ selectedMaxFaceLength }
                                  value={ selectedMinFaceLength }
                                  setValue={ setSelectedMinFaceLength } />
                <NumberInputField placeholder={ `max paddle length: ${ absoluteMaxLength }in` }
                                  label="Max paddle length"
                                  min={ selectedMinFaceLength }
                                  max={ absoluteMaxLength }
                                  value={ selectedMaxFaceLength }
                                  setValue={ setSelectedMaxFaceLength } />
                <NumberInputField placeholder={ `min weight: ${ absoluteMinWeight }oz` }
                                  label="Min weight"
                                  min={ absoluteMinWeight }
                                  max={ selectedMaxWeight }
                                  value={ selectedMinWeight }
                                  setValue={ setSelectedMinWeight } />
                <NumberInputField placeholder={ `max weight: ${ absoluteMaxWeight }oz` }
                                  label="Max weight"
                                  min={ selectedMinWeight }
                                  max={ absoluteMaxWeight }
                                  value={ selectedMaxWeight }
                                  setValue={ setSelectedMaxWeight } />
                <NumberInputField placeholder={ `min price: $${ absoluteMinPrice }` }
                                  label="Min price"
                                  min={ absoluteMinPrice }
                                  max={ selectedMaxPrice }
                                  value={ selectedMinPrice }
                                  setValue={ setSelectedMinPrice } />
                <NumberInputField placeholder={ `max price: $${ absoluteMaxPrice }` }
                                  label="Max price"
                                  min={ selectedMinPrice }
                                  max={ absoluteMaxPrice }
                                  value={ selectedMaxPrice }
                                  setValue={ setSelectedMaxPrice } />
                <hr />
                <span className="button is-link is-fullwidth" onClick={ resetFilters }>Clear Filters</span>
              </aside>
            </div>
            <div className="column is-12-mobile is-12-tablet is-9-desktop">
              <div className="columns is-mobile is-multiline">
                { filteredPaddles.map((paddle) => {
                  return (
                    <div className="column is-half-touch is-one-quarter-desktop" key={ paddle.name + paddle.brand }>
                      <PaddleCard paddle={ paddle }
                                  isSelected={ selectedPaddlesToCompare.hasOwnProperty(paddle.handle) }
                                  toggleSelected={ togglePaddleToCompare } />
                    </div>
                  )
                }) }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

