import { Paddle } from "../models/Paddle"

export interface Filters {
  brand: string[];
  gripLength: string[];
  gripCircumference: string[];
  coreMaterial: string[];
  surfaceMaterial: string[];
  priceRange: number[]; // tuple fixme not sure why TS complaining
  weightRange: number[]; // tuple fixme not sure why TS complaining
  paddleWidthRange: number[];
  paddleLengthRange: number[];
}

export const createFilters = (paddles: Paddle[]): Filters => {
  if (!paddles) {
    throw new Error("Unable to create filters with no paddle data")
  }

  const uniqueFilters = {
    brand: new Set<string>(),
    gripLength: new Set<string>(),
    gripCircumference: new Set<string>(),
    coreMaterial: new Set<string>(),
    surfaceMaterial: new Set<string>(),
    priceRange: [paddles[0].msrp, paddles[0].msrp],
    weightRange: [paddles[0].weight_min, paddles[0].weight_max],
    paddleWidthRange: [paddles[0].paddle_width, paddles[0].paddle_width],
    paddleLengthRange: [paddles[0].paddle_length, paddles[0].paddle_length],
  }

  paddles.forEach((paddle) => {
    uniqueFilters.brand.add(paddle.brand.toLowerCase().trim())
    uniqueFilters.gripCircumference.add(paddle.grip_circumference.toString())
    uniqueFilters.gripLength.add(paddle.grip_length.toString())
    uniqueFilters.coreMaterial.add(paddle.core_material)
    uniqueFilters.surfaceMaterial.add(paddle.surface_material)

    let msrp = Number(paddle.msrp)
    if (!isNaN(msrp)) {
      if (uniqueFilters.priceRange[0] > msrp) {
        uniqueFilters.priceRange[0] = msrp
      }

      if (uniqueFilters.priceRange[1] < msrp) {
        uniqueFilters.priceRange[1] = msrp
      }
    }

    if (uniqueFilters.weightRange[0] > paddle.weight_min) {
      uniqueFilters.weightRange[0] = paddle.weight_min
    } else if (uniqueFilters.weightRange[1] < paddle.weight_max) {
      uniqueFilters.weightRange[1] = paddle.weight_max
    }

    if (uniqueFilters.paddleWidthRange[0] > paddle.paddle_width) {
      uniqueFilters.paddleWidthRange[0] = paddle.paddle_width
    } else if (uniqueFilters.paddleWidthRange[1] < paddle.paddle_width) {
      uniqueFilters.paddleWidthRange[1] = paddle.paddle_width
    }

    if (uniqueFilters.paddleLengthRange[0] > paddle.paddle_length) {
      uniqueFilters.paddleLengthRange[0] = paddle.paddle_length
    } else if (uniqueFilters.paddleLengthRange[1] < paddle.paddle_length) {
      uniqueFilters.paddleLengthRange[1] = paddle.paddle_length
    }

  })

  return {
    brand: Array.from(uniqueFilters.brand).sort(),
    coreMaterial: Array.from(uniqueFilters.coreMaterial).sort(),
    gripCircumference: Array.from(uniqueFilters.gripCircumference).sort(),
    gripLength: Array.from(uniqueFilters.gripLength).sort(),
    priceRange: uniqueFilters.priceRange,
    surfaceMaterial: Array.from(uniqueFilters.surfaceMaterial).sort(),
    weightRange: uniqueFilters.weightRange,
    paddleWidthRange: uniqueFilters.paddleWidthRange,
    paddleLengthRange: uniqueFilters.paddleLengthRange,
  }
}

export const doesPaddleNameMatch = (paddle: Paddle, name: string) =>
  paddle.name.toLowerCase().includes(name.toLowerCase())

export const isPaddleSelectedBrand = (paddle: Paddle, brand: string) =>
  paddle.brand.toLowerCase().trim() === brand.toLowerCase()

export const isPaddleInPriceRange = (paddle: Paddle, min: number, max: number) =>
  paddle.msrp <= max && paddle.msrp >= min

export const isPaddleInWeightRange = (paddle: Paddle, min: number, max: number) =>
  paddle.weight_min <= max && paddle.weight_max >= min

export const isPaddleCoreOfType = (paddle: Paddle, type: string) =>
  paddle.core_material === type

export const isPaddleSurfaceOfType = (paddle: Paddle, type: string) =>
  paddle.surface_material === type

export const isPaddleDesiredGripLength =
  (paddle: Paddle, gripLength: number) => paddle.grip_length === gripLength

export const isPaddleDesiredGripCircumference =
  (paddle: Paddle, gripCircumference: number) => paddle.grip_circumference === gripCircumference

export const isPaddleWidthInRange = (paddle: Paddle, min: number, max: number) =>
  paddle.paddle_width <= max && paddle.paddle_width >= min

export const isPaddleLengthInRange = (paddle: Paddle, min: number, max: number) =>
  paddle.paddle_length <= max && paddle.paddle_length >= min

export const filterPaddlesByFilterCriteria = (paddles: Paddle[],
                                              name: string,
                                              brand: string,
                                              minPrice: number,
                                              maxPrice: number,
                                              minWeight: number,
                                              maxWeight: number,
                                              core: string,
                                              surface: string,
                                              gripLength: number,
                                              gripCircumference: number,
                                              minPaddleLength: number,
                                              maxPaddleLength: number,
                                              minPaddleWidth: number,
                                              maxPaddleWidth: number): Paddle[] => {

  return paddles.filter((paddle) => {
    if (brand && !isPaddleSelectedBrand(paddle, brand)) {
      return false
    }

    if (name && !doesPaddleNameMatch(paddle, name)) {
      return false
    }

    if (!isPaddleInWeightRange(paddle, minWeight, maxWeight)) {
      return false
    }

    if (!isPaddleInPriceRange(paddle, minPrice, maxPrice)) {
      return false
    }

    if (core && !isPaddleCoreOfType(paddle, core)) {
      return false
    }

    if (surface && !isPaddleSurfaceOfType(paddle, surface)) {
      return false
    }

    if (gripLength && !isPaddleDesiredGripLength(paddle, gripLength)) {
      return false
    }

    if (!isPaddleLengthInRange(paddle, minPaddleLength, maxPaddleLength)) {
      return false
    }

    if (!isPaddleWidthInRange(paddle, minPaddleWidth, maxPaddleWidth)) {
      return false
    }

    return !(gripCircumference && !isPaddleDesiredGripCircumference(paddle, gripCircumference))
  })
}
