import * as React from "react"
import "./styles.scss"

interface Props {
  label?: string;
  options: string[];
  value: string; // selected option
  setSelected: (x: string) => void;
  selectClass?: string; // appends class string to class of option tag
}

export const Dropdown = ({ label, options, value, setSelected, selectClass }: Props) => (
  <>
    { label ? <p className="menu-label">{ label }</p> : null }
    <div className="control dropdown-selector">
      <div className="select">
        <select value={ value }
                onChange={ (e) => setSelected(e.target.value) }
                className={ selectClass ? selectClass : "" }>
          <option disabled value="">{ `Select ${ label }` }</option>
          { options.map(option => {
            if (!option) return
            return (<option key={ option } value={ option }>{ option }</option>)
          }) }
        </select>
      </div>
    </div>
  </>
)
