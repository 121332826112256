import * as React from "react"
import "./styles.scss"
import { Paddle } from "../../models/Paddle"
import { Image } from "../Img"
import { Link } from "@reach/router"

interface Props {
  paddle: Paddle;
  isSelected: boolean;
  toggleSelected: (paddle) => void;
}

const toggle = (event, x) => {
  event.preventDefault()
}

export const PaddleCard = ({ paddle, isSelected, toggleSelected }: Props) => {
  const imageInfo = `${ paddle.brand } ${ paddle.name } Pickleball Paddle`
  return (
    <>
      <div id="PaddleCard" className="card">
        <div className="card-image" onClick={ (e) => toggle(e, toggleSelected(paddle)) }>
          <div className={ `compare-box ${ isSelected ? "is-selected" : "" }` } />
          <figure className="image is-4by3">
            <Image filePath={ paddle.image_urls[0] }
                   alt={ imageInfo }
                   title={ imageInfo } />
            <div className="card-image-overlay column">
              <div className="title is-5 has-text-white"> { `${ paddle.brand } ${ paddle.name }` }</div>
              <div className="subtitle is-6 has-text-white">${ paddle.msrp }</div>
            </div>
          </figure>
        </div>
        <Link to={ `product/${ paddle.handle }` } className="card-link-underline">
          <header className="card-footer">
            <p className="card-header-title is-centered">
              { paddle.name }
            </p>
          </header>
        </Link>
      </div>
    </>
  )
}
